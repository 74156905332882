@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
